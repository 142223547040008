.App {
  text-align: center;
  background-image: url(./mountain.jpg);
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-color: white;
  background-position: 37% 30%;
  min-height: 100vh;
}

.page-header-login-btn-container {
  text-align: right;
  justify-content: right;
  display: flex;
  padding: 1em !important;
}

.login-auth {
  background-color: rgba(155, 155, 155, 0.5);
}
